<template>
  <!-- 侧边 -->
  <div class="aside">
    <!-- 缩放 -->
    <div class="zoom">
      <i
        :class="!isCollapse ? 'icon-zoom-left' : 'icon-zoom-right'"
        :style="{ marginLeft: [isCollapse ? '1vw' : ''] }"
        @click="zoomClick(!isCollapse)"
      />
    </div>
    <!-- 导航 -->
    <el-menu
      :default-active="activeIndex"
      :unique-opened="true"
      :collapse="isCollapse"
      @open="handleOpen"
    >
      <el-submenu
        v-for="nav in navs"
        :key="nav.title"
        :index="nav.title"
        :class="nav.title == submenuTitle ? 'submenu' : ''"
      >
        <template slot="title">
          <img
            class="nav-category-title-icon"
            :src="nav.icon"
            alt=""
            :style="{ marginLeft: [isCollapse ? '0vw' : ''] }"
          />
          <span
            slot="title"
            :style="{ color: [nav.title == submenuTitle ? ' #3D6EFF' : ''] }"
            >{{ nav.title }}</span
          >
        </template>
        <el-menu-item-group v-for="item in nav.child" :key="item.title">
          <el-menu-item
            :index="'' + item.url"
            :class="activeCategoryIndex.includes(item.url) ? 'active' : ''"
          >
            <router-link
              :style="{ marginLeft: [isCollapse ? '1.2vw' : ''] }"
              :class="
                activeCategoryIndex.includes(item.url)
                  ? 'nav-category-list-item active'
                  : 'nav-category-list-item'
              "
              :to="item.url"
            >
              <!-- 左侧圆点 -->
              <div class="nav-category-list-item-circle"></div>
              <!-- 名称 -->
              <p>{{ item.title }}</p>
            </router-link>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
    <!-- 存储空间 -->
    <div class="storage" v-show="!isCollapse">
      <div class="storage-top">
        <div class="storage-top-item">
          <span class="storage-title">云空间</span>
        </div>
        <div class="storage-top-item">
          <span class="storage-usage"
            >{{ storageUsage.useSpaceFormat }}/{{
              storageUsage.totalSpaceFormat
            }}</span
          >
          <router-link class="link" to="/serviceUpgrade">扩容</router-link>
        </div>
      </div>
      <div class="storage-bottom">
        <el-progress
          :percentage="storagePercentage"
          :color="progressColor"
          :show-text="false"
        ></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $bus from 'p/utils';
import workbench from 'v/workbench/Workbench';
export default {
  name: 'Aside',
  components: {
    workbench
  },
  data() {
    return {
      activeIndex: '',
      activeCategoryIndex: '/workbench/workspace',
      submenuTitle: '',
      isCollapse: false,
      // 导航数据
      navs: [
        {
          title: '数据中心',
          icon: require('a/workbench/icon/icon-nav-data.png'),
          child: [
            {
              title: '工作空间',
              url: '/workbench/workspace'
            },
            {
              title: '我的服务',
              url: '/workbench/myService'
            },
            {
              title: '发布服务',
              url: '/workbench/releaseService'
            },
            {
              title: '文件管理',
              url: '/workbench/fileManager'
            },
            {
              title: '图层管理',
              url: '/workbench/map/layerManager'
            },
            {
              title: '数据管理',
              url: '/workbench/map/dataManager'
            },
            {
              title: '我的素材',
              url: '/workbench/map/materialLibrary/iconLibrary'
            }
          ]
        },
        {
          title: '费用',
          icon: require('a/workbench/icon/icon-nav-expense.png'),
          child: [
            {
              title: '购买记录',
              url: '/workbench/buyRecord'
            }
          ]
        },
        {
          title: '账号',
          icon: require('a/workbench/icon/icon-nav-account.png'),
          child: [
            // {
            //   title: '账号信息',
            //   url: '/workbench/account/info'
            // },
            // {
            //   title: '消息中心',
            //   url: '/workbench/account/message'
            // },
            {
              title: '开发者认证',
              url: '/workbench/account/authentication'
            },
            {
              title: '我的券包',
              url: '/workbench/account/couponPack'
            },
            {
              title: '设置',
              url: '/workbench/map/setting'
            }
          ]
        }
      ],
      // 存储空间使用
      storageUsage: {
        useSpace: 0,
        useSpaceFormat: '',
        totalSpace: 0,
        totalSpaceFormat: ''
      },
      storagePercentage: 0
    };
  },
  computed: {
    progressColor() {
      let color = '#507CFF';
      if (this.storagePercentage >= 90) {
        color = '#ed1c24';
      } else if (this.storagePercentage >= 70) {
        color = '#ff7f27';
      }
      return color;
    }
  },
  mounted() {
    // 监听刷新储存储空间使用情况
    var that = this;
    $bus.$off('fileSpace');
    $bus.$on('fileSpace', () => {
      that.getFileSpace();
    });
  },
  created() {
    const hash = location.hash;
    // 匹配当前路由
    this.matchNav(hash);
    this.activeIndex = hash.slice(1);
    this.handleOpen();
    this.mapMenu(this.$route.fullPath);
    this.getFileSpace();
  },
  methods: {
    // 获取储存储空间使用情况
    getFileSpace() {
      this.$service.file.fileSpace().then(res => {
        if (res.data.status === 200) {
          this.storageUsage = res.data.data;
          this.storagePercentage =
            (res.data.data.useSpace / res.data.data.totalSpace) * 100;
        }
      });
    },
    // 根据地址匹配导航栏
    matchNav(path) {
      this.activeCategoryIndex = path;
    },
    zoomClick(res) {
      this.isCollapse = res;
      this.$emit('getAsideWidht', res);
    },
    handleOpen(key) {
      this.submenuTitle = key;
    },
    // 监听路由为map菜单栏收缩
    mapMenu(data) {
      if (
        data.includes('workbench/map') &&
        !data.includes('workbench/map/setting')
      ) {
        this.isCollapse = true;
        this.$emit('getAsideWidht', true);
      } else {
        this.isCollapse = false;
        this.$emit('getAsideWidht', false);
      }
    }
  },
  watch: {
    // 监听路由变化
    $route(route) {
      this.matchNav(route.path);
      this.mapMenu(route.path);
    }
  }
};
</script>

<style lang="less" scoped>
.aside {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: #f9f9f9;
  box-shadow: 1px 13px 12px 1px rgba(236, 239, 255, 0.5);
}

// 导航
.nav {
  // 分类
  &-category {
    // 分类列表
    &-list {
      margin-top: 35px;
      &-item {
        display: flex;
        align-items: center;
        height: 52px;
        border-radius: 6px;
        font-size: 18px;
        color: #333333;
        cursor: pointer;
        &.active {
          .nav-category-list-item-circle {
            background: #507cff;
            border: none;
          }
        }
        // 列表左侧圆点
        &-circle {
          box-sizing: border-box;
          width: 8px;
          height: 8px;
          margin: 0 22px 0 -20px;
          border: 1px solid #d2d2d2;
          border-radius: 50%;
        }
      }
    }
  }
}

// 导航栏
/deep/.el-submenu {
  img {
    margin: 0 14px 0 8px;
    width: 24px;
    min-width: 24px;
  }
  &__title {
    display: flex;
    align-items: center;
    height: 80px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    &:hover {
      background: transparent !important;
    }
  }
  .el-icon-arrow-down:before {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
  }
}
/deep/.el-menu-item {
  margin-left: 16px;
  width: 228px;
  height: 52px;
  text-align: center;
  &:hover {
    width: 228px;
    height: 52px;
    background: #eef1fc;
    border-radius: 6px;
  }
  &.active {
    background: #eef1fc;
    border-radius: 6px;
  }
}
/deep/.el-menu {
  max-height: calc(100% - 140px);
  overflow-y: auto;
  border: none;
  background: #f9f9f9;
  &-item-group__title {
    padding: 0px;
  }
  &-item-group > ul {
    overflow: hidden;
  }
}
// 存储空间
.storage {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 224px;
  margin: 0 auto;
  &-top {
    margin-bottom: 6px;
    &-item {
      display: flex;
      justify-content: space-between;
    }
  }
  &-title {
    margin-right: 16px;
    color: #333333;
    font-size: 16px;
  }
  &-usage {
    color: #999999;
    font-size: 14px;
  }
  /deep/.el-progress-bar__outer {
    height: 6px !important;
  }
}
// 缩放按钮
.zoom {
  height: 47px;
  width: 100%;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  .icon-zoom-left {
    margin-left: 27px;
    height: 26px;
    width: 26px;
    background-size: contain;
    background-image: url('~a/workbench/icon/icon-zoom-left.png');
  }
  .icon-zoom-right {
    margin-left: 27px;
    height: 26px;
    width: 26px;
    background-size: contain;
    background-image: url('~a/workbench/icon/icon-zoom-right.png');
  }
}
.link {
  font-size: 16px;
}
.submenu {
  /deep/.el-icon-arrow-down:before {
    color: #3d6eff;
  }
}
</style>
