<template>
  <!-- 传输面板 -->
  <div class="transfer">
    <!-- 面板 -->
    <div class="transfer-panel" v-show="transferPanelToggle">
      <!-- 头部 -->
      <div class="transfer-panel-header">
        <p class="transfer-panel-header-title">上传记录</p>
        <i
          class="el-icon-close"
          @click="transferPanelToggle = false"
          style="cursor: pointer"
        />
      </div>
      <div class="transfer-panel-content">
        <div class="transfer-list-btn">
          <el-button
            @click="statusSwitching = true"
            :class="statusSwitching ? 'btn1' : ''"
            >正在上传（{{ underwayList.length }}）</el-button
          >
          <el-button
            @click="statusSwitching = false"
            :class="!statusSwitching ? 'btn1' : ''"
            >已完成（{{ completedList.length }}）</el-button
          >
        </div>
        <!-- 正传上传 -->
        <div class="transfer-list" v-show="statusSwitching">
          <div
            class="transfer-list-item"
            v-for="item in underwayList"
            :key="'underway-' + item.id"
          >
            <!-- 左侧图片，表示上传的是文件还是文件夹 -->
            <img
              class="transfer-list-item-typeImg"
              :src="require('a/workbench/file.png')"
              alt=""
            />
            <!-- 上传进度 -->
            <div class="transfer-progress-wrap">
              <!-- 进度条上边部分 -->
              <div class="transfer-progress-top">
                <p class="transfer-list-item-filename" :title="item.name">
                  {{ item.name }}
                </p>
                <p
                  v-if="item.transfreState === 'analysis'"
                  class="transfer-list-item-analysis"
                >
                  加载数据中{{ item.md5Percentage }}%<i
                    class="el-icon-loading"
                  ></i>
                </p>
                <!-- 错误 -->
                <p
                  v-else-if="item.transfreState === 'retry'"
                  class="transfer-list-item-error"
                >
                  网络出错，稍后重试
                </p>
                <!-- 合并文件 -->
                <p
                  v-else-if="
                    item.transfreState === 'pause' && item.percentage >= 100
                  "
                  class="transfer-list-item-merge"
                >
                  文件合并中...
                </p>
                <p v-else>{{ item.loadedSize }}/{{ item.loadedTotal }}</p>
              </div>
              <!-- 进度条 -->
              <el-progress
                class="transfer-progress"
                :show-text="false"
                :percentage="item.percentage"
                :color="customColor"
              />
            </div>
            <!-- 右侧上传控制按钮 -->
            <div class="transfer-list-item-icon">
              <!-- 暂停、继续、等待、成功 -->
              <i
                v-if="item.percentage < 100 || item.transfreState === 'retry'"
                :class="['icon', 'icon-transfer-' + item.transfreState]"
                @click="operation(item)"
              ></i>
              <!-- 移除 -->
              <i
                class="icon icon-transfer-remove"
                @click="remove(item, 'underway')"
              ></i>
            </div>
          </div>
        </div>
        <!-- 已完成 -->
        <div class="transfer-list" v-show="!statusSwitching">
          <div
            class="transfer-list-item"
            v-for="item in completedList"
            :key="'completed-' + item.id"
          >
            <!-- 左侧图片，表示上传的是文件还是文件夹 -->
            <div class="transfer-list-item-left">
              <img
                class="transfer-list-item-typeImg"
                :src="require('a/workbench/file.png')"
                alt=""
              />
              <p class="transfer-list-item-filename" :title="item.name">
                {{ item.name }}
              </p>
            </div>
            <!-- 右侧上传控制按钮 -->
            <div class="transfer-list-item-icon">
              <p>{{ item.loadedTotal }}</p>
              <!-- 跳转到文件夹 -->
              <i class="icon icon-transfer-folder" @click="jump(item)"></i>
              <i
                class="icon icon-transfer-remove"
                @click="remove(item, 'completed')"
              ></i>
            </div>
          </div>
        </div>
        <p class="transfer-list-hint">
          注意：上传过程中请勿刷新或者关闭当前页面避免造成数据丢失
        </p>
        <div class="transfer-list-empty">
          <span
            v-show="completedList.length >= 1 && !statusSwitching"
            @click="handleEmpty"
            >清空</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $bus from 'p/utils';
export default {
  name: 'Transfer',
  data() {
    return {
      // 进度条颜色
      customColor: '#507cff',
      statusSwitching: true
    };
  },
  computed: {
    underwayList() {
      return this.fileTransList.filter(
        item => item.transfreState !== 'success'
      );
    },
    completedList() {
      const completedListJSON = localStorage.completedList;
      let completedList = completedListJSON
        ? JSON.parse(completedListJSON)[localStorage.userName]
        : [];
      return this.fileTransList
        .filter(item => item.transfreState === 'success')
        .concat(completedList)
        .map(item => {
          const { folder, id, loadedTotal, name } = item;
          return {
            folder,
            id,
            loadedTotal,
            name
          };
        });
    },
    fileTransList: {
      get() {
        return this.$store.state.file.fileTransList;
      },
      set(val) {
        this.$store.commit('file/setFileTransList', val);
      }
    },
    transferPanelToggle: {
      get() {
        return this.$store.state.file.transferPanelToggle;
      },
      set(val) {
        this.$store.commit('file/setTransferPanelToggle', val);
      }
    }
  },
  beforeCreate() {
    const userName = localStorage.userName;
    let completedListJSON = localStorage.completedList;
    // 首次初始化
    if (!completedListJSON) {
      completedListJSON = JSON.stringify({ [userName]: [] });
    } else if (!JSON.parse(completedListJSON)[userName]) {
      // 不同账号首次初始化
      let completedList = JSON.parse(completedListJSON);
      completedList[userName] = [];
      completedListJSON = JSON.stringify(completedList);
    }
    localStorage.completedList = completedListJSON;
  },
  mounted() {
    // 监听刷新储存储空间使用情况
    const that = this;
    $bus.$off('transfer');
    $bus.$on('transfer', val => {
      that.transferPanelToggle = val;
    });
    // 关闭前保存已完成记录
    window.onbeforeunload = () => {
      let completedList = JSON.parse(localStorage.completedList);
      completedList[localStorage.userName] = this.completedList;
      localStorage.completedList = JSON.stringify(completedList);
    };
  },
  methods: {
    // 清空已完成列表
    handleEmpty() {
      let completedList = JSON.parse(localStorage.completedList);
      completedList[localStorage.userName] = [];
      localStorage.completedList = JSON.stringify(completedList);
      this.fileTransList = this.fileTransList.filter(
        item => item.transfreState !== 'success'
      );
    },
    // 操作
    operation(item) {
      const { transfreState } = item;
      // 成功、等待时无操作
      if (['success', 'await'].indexOf(transfreState) !== -1) return;
      // 暂停
      if (transfreState === 'pause') {
        // 修改当前任务状态
        item.transfreState = 'start';
        // 取消请求
        item.controller.abort('pause');
        // 判断是否有等待中的任务
        if (this.underwayList.length > 3) {
          // 开始第一个等待中的任务
          for (let i = 0; i < this.underwayList.length; i++) {
            const task = this.underwayList[i];
            if (task.transfreState === 'await') {
              // 开始任务
              task.start();
            }
          }
        }
      }
      // 继续、重试
      if (transfreState === 'start' || transfreState === 'retry') {
        // 当正在进行中的任务小于3个时
        if (
          this.underwayList.filter(item => item.transfreState === 'pause')
            .length < 3
        ) {
          // 判断是在上传切片还是第一步获取切片信息
          if (item.start) {
            // 开始任务
            item.start();
          } else if (item.getUploadInfo) {
            // 获取切片信息
            item.getUploadInfo();
          }
        } else {
          // 进入等待状态
          item.transfreState = 'await';
        }
      }
    },
    // 移除
    remove(item, type) {
      const id = item.id;
      // 移除正在上传项
      if (type === 'underway') {
        // 如果是在文件加载中
        if (item.transfreState === 'analysis') {
          item.bmf.abort();
        } else {
          // 取消上传
          // 首次等待状态的任务没有controller
          item.controller && item.controller.abort('remove');
          this.$service.file.fileUploadCancel({ taskId: item.taskId });
          // 移除正在上传中的任务时，判断是否等待中的任务
          if (item.transfreState === 'pause' && this.underwayList.length > 3) {
            // 开始第一个等待中的任务
            for (let i = 0; i < this.underwayList.length; i++) {
              const task = this.underwayList[i];
              if (task.transfreState === 'await') {
                // 开始任务
                task.start();
              }
            }
          }
        }
      }
      // 移除已完成项
      if (type === 'completed') {
        let completedList = JSON.parse(localStorage.completedList);
        completedList[localStorage.userName] = completedList[
          localStorage.userName
        ].filter(data => data.id !== id);

        localStorage.completedList = JSON.stringify(completedList);
      }
      this.fileTransList = this.fileTransList.filter(data => data.id !== id);
    },
    // 跳转到指定目录
    jump(item) {
      $bus.$emit('jumpToFolder', item.folder);
    }
  }
};
</script>

<style lang="less" scoped>
/* 隐藏上传按钮 */
#global-uploader-btn {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.global-uploader-single {
  #global-uploader-btn {
    position: relative;
  }
}
</style>

<style lang="less" scoped>
// 传输
.transfer {
  position: relative;
  // 传输面板
  &-panel {
    position: absolute;
    top: 35px;
    right: -90px;
    width: 450px;
    padding-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px -5px 10px 1px rgba(217, 217, 217, 0.2),
      0px 5px 10px 1px rgba(217, 217, 217, 0.5);
    border-radius: 6px;
    &-header {
      display: flex;
      justify-content: space-between;
      height: 48px;
      line-height: 48px;
      padding: 0 20px;
      border-bottom: 1px solid #e5e5e5;
      &-title {
        color: #333333;
        font-size: 18px;
      }
      .el-icon-close {
        margin-top: 15px;
        font-size: 22px;
      }
      &-btn {
        font-size: 16px;
      }
    }
    &::after {
      content: ' ';
      position: absolute;
      top: -12px;
      right: 88px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      border-width: 0 12px 24px 12px;
    }
  }
  // 传输列表
  &-list {
    height: 244px;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    // 上传状态切换按钮
    &-btn {
      display: flex;
      align-content: space-between;
      margin: 10px 20px;
      .el-button {
        display: inline-block;
        width: 200px;
        height: 34px;
        font-size: 16px;
        color: #333;
      }
      .btn1 {
        background: #507cff;
        color: #fff;
      }
    }
    &-empty {
      float: right;
      margin-right: 20px;
      font-size: 16px;
      cursor: pointer;
    }
    &-hint {
      font-size: 12px;
      color: #999;
      line-height: 30px;
      margin-left: 20px;
    }
    // 空状态
    .no-file {
      width: 100%;
      text-align: center;
      margin-top: 80px;
      font-size: 16px;
    }
    // 列表
    .file-list {
      height: 244px;
      overflow-y: auto;
    }

    &-item {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 15px 20px 15px 30px;
      &-left {
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
      &-filename {
        width: 0;
        flex: 1;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-analysis,
      &-merge {
        color: #507cff;
      }
      &-error {
        color: #ff7f27;
      }
      // 左侧类型图片
      &-typeImg {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
      // 右侧icon
      &-icon {
        display: flex;
        align-items: center;
        .icon {
          margin-left: 14px;
          cursor: pointer;
        }
      }
    }
  }
  // 传输进度
  &-progress {
    &-wrap {
      flex: 1;
      margin-right: 20px;
    }
    // 传输进度头部
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      font-size: 14px;
      color: #333;
    }
  }
}
</style>
