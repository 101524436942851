<template>
  <!-- 用户信息 -->
  <div class="user">
    <!-- 头像 -->
    <div class="user-toggle">
      <img class="user-avatar" :src="userImg" alt="" @click="show = !show" />
    </div>
    <!-- 信息面板 -->
    <div
      :class="trianglePosition == true ? 'user-info' : 'user-info home'"
      v-show="show"
    >
      <!-- 关闭按钮 -->
      <img
        class="user-info-close"
        src="~a/workbench/close.png"
        alt=""
        @click="closeClick"
      />
      <!-- 基础信息 -->
      <div class="user-info-basic">
        <!-- 头像 -->
        <img class="user-info-basic-avatar" :src="userImg" alt="" />
        <!-- 账号信息 -->
        <div class="user-info-basic-account">
          <div class="user-info-basic-account-tr">
            <p class="user-info-basic-account-name" v-show="loginData">
              {{ loginData }}
            </p>
            <p class="user-info-basic-account-name" v-show="!loginData">
              用户名称
            </p>
          </div>
          <div class="user-info-basic-account-tr">
            <p
              class="user-info-basic-account-state user-info-basic-account-state-0"
            >
              <!-- 未认证开发者 -->
            </p>
            <span style="margin-left: 1.5vw; cursor: pointer" @click="quitClick"
              >退出</span
            >
          </div>
        </div>
      </div>
      <!-- 个人资源 -->
      <div class="user-info-resource">
        <!-- 下半部 -->
        <div class="user-info-resource-left">
          <template v-if="quota">
            <!-- 存储空间 -->
            <div class="user-info-resource-item">
              <span class="user-info-resource-item-typeName">云储存空间</span>
              <span class="user-info-resource-item-use"
                >{{ quota.useStorageSpaceFormat }}/{{
                  quota.storageSpaceFormat
                }}</span
              >
            </div>
            <div class="user-info-resource-item">
              <span class="user-info-resource-item-typeName">服务发布量</span>
              <span class="user-info-resource-item-use"
                >{{ quota.gissCanUseCount }}/{{ quota.gissCount }}</span
              >
            </div>
            <!-- <div class="user-info-resource-item">
            <span class="user-info-resource-item-typeName">数据集数量</span>
            <span class="user-info-resource-item-use">3/5</span>
          </div> -->
          </template>
        </div>
        <div class="user-info-resource-right">
          <router-link to="/serviceUpgrade">
            <p class="user-info-resource-upgrade">
              升级扩容<img
                class="user-info-resource-upgrade-icon"
                src="~a/workbench/arrow-right-circle.png"
                alt=""
              />
            </p>
          </router-link>
        </div>
      </div>
      <!-- 快捷按钮 -->
      <div class="user-info-shortcutBtn">
        <div
          class="user-info-shortcutBtn-item"
          v-for="item in shortcutBtns"
          :key="item.name"
          @mouseenter="item.active = true"
          @mouseleave="item.active = false"
        >
          <!-- 图标 -->
          <router-link class="user-info-shortcutBtn-item-icon" :to="item.url">
            <img
              :src="item.active ? item.activeIcon : item.icon"
              alt=""
              :title="item.title"
              style="width: 2.4vw"
            />
          </router-link>
          <span class="user-info-shortcutBtn-item-name">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
  data() {
    return {
      show: false,
      userImg: require('a/workbench/avatar-m.png'),
      trianglePosition: false,
      shortcutBtns: [
        {
          name: '发布服务',
          icon: require('a/workbench/fbfw.png'),
          activeIcon: require('a/workbench/fbfw-active.png'),
          active: false,
          url: '/workbench/releaseService'
        },
        {
          name: '权益升级',
          icon: require('a/workbench/qysj.png'),
          activeIcon: require('a/workbench/qysj-active.png'),
          active: false,
          url: '/serviceUpgrade'
        },
        {
          name: '设置',
          icon: require('a/workbench/zhsz.png'),
          activeIcon: require('a/workbench/zhsz-active.png'),
          active: false,
          url: '/workbench/map/setting'
        },
        {
          name: '联系客服',
          icon: require('a/workbench/lxkf.png'),
          activeIcon: require('a/workbench/lxkf-active.png'),
          active: false,
          url: '',
          title: '0755-8633-6555'
        }
      ],
      quota: ''
    };
  },
  created() {
    this.trianglePosition = this.$route.path.includes('workbench');
  },
  computed: {
    loginData: {
      get() {
        return this.$store.state.userData.loginData;
      }
    }
  },
  methods: {
    getUserInfo() {
      this.$service.quota.quotaUser().then(res => {
        const { status, data } = res;
        if (status === 200) {
          this.quota = data;
        }
      });
    },
    closeClick() {
      this.show = false;
    },
    quitClick() {
      localStorage.userName = '';
      localStorage.token = '';
      this.$store.commit('userData/setLoginData', false);
      localStorage.redirectUrl = '';
      this.$router.push('/');
    }
  },
  watch: {
    show(show) {
      if (show) {
        this.getUserInfo();
      }
    },
    // 监听路由变化
    $route(route) {
      this.trianglePosition = route.path.includes('workbench');
    }
  }
};
</script>

<style lang="less" scoped>
.user {
  &-toggle {
    font-size: 0;
  }
  // 头像
  &-avatar {
    width: 30px;
    height: 30px;
    background: #dcdcdc;
    border: 1px solid #507cff;
    border-radius: 50%;
    cursor: pointer;
  }
  // 信息面板
  &-info {
    position: fixed;
    z-index: 1;
    top: 85px;
    right: 20px;
    width: 428px;
    padding: 20px 0 24px;
    background: #ffffff;
    box-shadow: 0px -5px 10px 1px rgba(217, 217, 217, 0.2),
      0px 5px 10px 1px rgba(217, 217, 217, 0.5);
    border-radius: 6px;
    // 顶部三角
    &::before {
      position: absolute;
      top: -20px;
      right: 32px;
      content: ' ';
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }

    // 关闭按钮
    &-close {
      position: absolute;
      top: 14px;
      right: 14px;
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
    // 基础信息
    &-basic {
      display: flex;
      margin: 0 20px 20px;
      // 头像
      &-avatar {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        background: #e5e5e5;
        border-radius: 50%;
      }
      // 账号信息
      &-account {
        flex: 1;
        font-size: 18px;
        color: #666666;
        &-tr {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &-name {
          color: #333333;
          font-size: 20px;
          font-weight: bold;
        }
        &-id {
          margin-right: 40px;
        }
        // 状态
        &-state {
          &-0 {
            color: #999999;
          }
        }
      }
    }
    // 个人资源
    &-resource {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 388px;
      height: 56px;
      padding: 12px 4px 6px 10px;
      margin: 0 auto 24px;
      background-image: url(~a/workbench/resource-bg.png);
      background-size: 100% 100%;
      // 右
      &-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
      }
      // 升级扩容
      &-upgrade {
        display: flex;
        align-items: center;
        color: #693523;
        font-size: 18px;
        &-icon {
          width: 20px;
          height: 20px;
          margin-left: 4px;
        }
      }
      // 左
      &-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
      }
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        color: #fff;
        font-size: 14px;
        &:last-child {
          margin-right: 0;
        }
        &-typeName {
          margin-bottom: 10px;
        }
      }
    }
    // 快捷方式
    &-shortcutBtn {
      display: flex;
      justify-content: space-evenly;
      color: #000;
      font-size: 14px;
      text-align: center;
      &-item {
        display: flex;
        flex-direction: column;
        &-icon {
          width: 48px;
          height: 48px;
          margin-bottom: 14px;
          // 去除图片下方撑起的空白部分
          font-size: 0;
        }
      }
    }
  }
  .home {
    top: 75px;
    &::before {
      right: 232px;
    }
  }
}
</style>
