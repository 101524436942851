<template>
  <!-- 头部 -->
  <div class="header">
    <!-- 左侧 -->
    <div class="header-left" @click="goWorkbench">
      <img class="logo" src="@/assets/logo.png" alt="" />
      <img class="logo-text" src="@/assets/workbench/title.png" alt="" />
    </div>
    <!-- 右侧 -->
    <div class="header-right">
      <!-- 传输按钮 -->
      <div
        :class="
          transferPanelToggle
            ? 'header-right-icon transfer'
            : 'header-right-icon'
        "
        @click.stop="transferPanelToggle = !transferPanelToggle"
      >
        <!-- 面板显示隐藏按钮 -->
        <el-badge
          :value="underwayList.length"
          :hidden="underwayList.length == 0"
        >
          <el-tooltip
            content="文件传输面板"
            :visible-arrow="false"
            :enterable="false"
            transition="none"
            popper-class="atoolfiletip"
          >
            <div class="transfer-toggle">
              <i class="icon icon-transfer"></i>
            </div>
          </el-tooltip>
        </el-badge>
      </div>
      <Transfer class="notice" />
      <!-- 通知 -->
      <!-- <list-tooltip content="通知">
        <div class="header-right-icon">
          <i class="icon icon-notice" />
        </div>
      </list-tooltip> -->
      <!-- 关于我们 -->
      <!-- <list-tooltip content="关于我们">
        <div
          :class="
            aboutUsDialogVisible
              ? 'header-right-icon aboutUs'
              : 'header-right-icon'
          "
          @click="handleAboutUs()"
        >
          <i class="icon icon-aboutUs"></i>
        </div>
      </list-tooltip> -->
      <!-- 帮助 -->
      <!-- <list-tooltip content="帮助">
        <div class="header-right-icon"><i class="icon icon-help"></i></div>
      </list-tooltip> -->
      <!-- 头像 -->
      <User />
    </div>
    <!-- 关于我们窗口 -->
    <AboutUs />
  </div>
</template>

<script>
import User from 'c/workbench/User';
import Transfer from 'c/workbench/Transfer';
import AboutUs from 'c/workbench/AboutUs.vue';
// import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  name: 'Header',
  components: {
    User,
    Transfer,
    AboutUs
    // ListTooltip
  },
  computed: {
    underwayList() {
      return this.fileTransList.filter(
        item => item.transfreState !== 'success'
      );
    },
    fileTransList: {
      get() {
        return this.$store.state.file.fileTransList;
      },
      set(val) {
        this.$store.commit('file/setFileTransList', val);
      }
    },
    transferPanelToggle: {
      get() {
        return this.$store.state.file.transferPanelToggle;
      },
      set(val) {
        this.$store.commit('file/setTransferPanelToggle', val);
      }
    },
    aboutUsDialogVisible: {
      get() {
        return this.$store.state.common.aboutUsDialogVisible;
      },
      set(val) {
        this.$store.commit('common/setAboutUsDialogVisible', val);
      }
    }
  },
  methods: {
    handleAboutUs() {
      this.$store.commit('common/setAboutUsDialogVisible', true);
    },
    goWorkbench() {
      this.$router.push({ path: '/workbench/workspace' });
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 48px 0 38px;
  background-color: #fff;
  box-shadow: 0px 5px 10px 1px rgba(236, 239, 255, 0.5);
  // 左侧
  &-left {
    display: flex;
    align-items: center;
    cursor: pointer;
    // logo
    .logo {
      width: 156px;
      height: 37px;
      margin-right: 26px;
      &-text {
        width: 64px;
        height: 22px;
      }
    }
  }
  // 右侧
  &-right {
    display: flex;
    align-items: center;
    &-icon {
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 16px;
      cursor: pointer;
      margin-right: 20px;
      .icon {
        margin: auto;
      }
      .icon-transfer {
        margin-left: 8px;
        margin-top: 4px;
      }
      &:hover {
        background: rgba(80, 124, 255, 0.1);
        .icon-aboutUs {
          margin-top: 7px;
          width: 24px;
          height: 24px;
          background-image: url('~a/workbench/icon-aboutUs.png');
        }
        .icon-help {
          margin-top: 7px;
          width: 24px;
          height: 24px;
          background-image: url('~a/workbench/help1.png');
        }
        .icon-help {
          margin-top: 7px;
          width: 24px;
          height: 24px;
          background-image: url('~a/workbench/help1.png');
        }

        .icon-notice {
          margin: auto;
          margin-top: 7px;
          width: 24px;
          height: 24px;
          background-image: url('~a/notice1.png');
        }
        .icon-transfer {
          margin-top: 2px;
          width: 24px;
          height: 24px;
          background-image: url('~a/workbench/icon/icon-transfer-active.png');
        }
      }
    }
    .transfer {
      background: rgba(80, 124, 255, 0.1);
      .icon-transfer {
        margin-top: 2px;
        width: 24px;
        height: 24px;
        background-image: url('~a/workbench/icon/icon-transfer-active.png');
      }
    }
    .notice {
      background: rgba(80, 124, 255, 0.1);
      .icon-notice {
        margin: auto;
        margin-top: 7px;
        width: 24px;
        height: 24px;
        background-image: url('~a/notice1.png');
      }
    }
    .aboutUs {
      background: rgba(80, 124, 255, 0.1);
      .icon-aboutUs {
        margin-top: 7px;
        width: 24px;
        height: 24px;
        background-image: url('~a/workbench/icon-aboutUs.png');
      }
    }
  }
  /deep/.el-badge__content {
    background-color: red;
    height: 15px;
    border-radius: 50%;
    line-height: 15px;
    font-size: 12px;
    padding: 0 4px;
  }
}
</style>
<style lang="less">
// 提示语
.atoolfiletip {
  background: rgba(89, 89, 89, 0.8) !important;
  padding: 5px 8px !important;
  margin: 0;
  margin-top: 17px !important;
  font-size: 12px;
  user-select: none;
}
</style>
