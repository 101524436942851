<template>
  <div class="guide-wrap">
    <div class="guide">
      <div class="guide-header">
        <div class="guide-header-title">
          <p>服务发布操作指引</p>
          <p class="guide-header-title-step">
            （<span class="guide-header-title-step-current">{{ step }}</span
            >/4）
          </p>
        </div>
        <div class="guide-header-right">
          <Button class="guide-jump-btn" @click="end">{{ jumpBtnText }}</Button>
        </div>
      </div>
      <div class="guide-body">
        <div class="guide-body-title">{{ guideContent }}</div>
        <div class="guide-carousel">
          <div class="guide-carousel-prev">
            <Button
              class="guide-step-btn"
              :disabled="step === 1"
              @click.capture="step--"
            >
              <img
                v-if="step === 1"
                src="~a/workbench/guide-prev-disabled.png"
                alt=""
              />
              <img v-else src="~a/workbench/guide-prev.png" alt="" />
            </Button>
          </div>
          <div class="guide-carousel-content">
            <img class="guide-img" :src="guideImg" />
          </div>
          <div class="guide-carousel-next">
            <Button
              class="guide-step-btn"
              :disabled="step === 4"
              @click.capture="step++"
            >
              <img
                v-if="step === 4"
                src="~a/workbench/guide-next-disabled.png"
                alt=""
              />
              <img v-else src="~a/workbench/guide-next.png" alt="" />
            </Button>
          </div>
          <div class="guide-carousel-dots">
            <div
              class="guide-carousel-dots-item"
              v-for="i in 4"
              :key="i"
              :class="step === i ? 'active' : ''"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'c/basic/Button.vue';
export default {
  name: 'Guide',
  components: { Button },
  data() {
    return {
      step: 1
    };
  },
  computed: {
    jumpBtnText() {
      return this.step < 4 ? '跳过' : '完成';
    },
    guideImg() {
      return require(`a/workbench/guide-${this.step}.png`);
    },
    guideContent() {
      return [
        '数据标绘：在【数据管理】中进行数据标绘，将标好的数据保存在数据目录中',
        '数据加载：在【图层管理】中新建图层，将已保存的数据添加至图层中',
        '发布服务：在【发布服务】中，将已创建的图层发布为服务',
        '服务管理：在【我的服务】中，查看和管理已发布的服务'
      ][this.step - 1];
    }
  },
  created() {
    localStorage.guideState = 1;
  },
  methods: {
    end() {
      this.$emit('end');
    }
  }
};
</script>

<style lang="less" scoped>
.guide {
  width: 985px;
  background-color: #fff;
  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &-header {
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 20px;
      font-weight: bold;
      &-step {
        color: #999;
        &-current {
          color: #507cff;
        }
      }
    }
  }
  &-jump-btn {
    width: 60px;
    height: 26px;
    background: #507cff;
    border-radius: 4px;
    font-size: 16px;
  }
  &-body {
    padding: 24px 20px;
    &-title {
      margin-bottom: 25px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  &-carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 100px;
      display: flex;
      justify-content: center;
      &-item {
        width: 10px;
        height: 6px;
        margin: 0 4px;
        border: 1px solid #507cff;
        border-radius: 2px;
        &.active {
          background: #507cff;
        }
      }
    }
  }
  &-step-btn {
    width: 22px;
    height: 22px;
    background: none;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-img {
    width: 895px;
    height: 407px;
  }
}
</style>
