<template>
  <!-- 工作台 -->
  <el-container class="workbench">
    <!-- 头部 -->
    <el-header class="workbench-header" height="3.64583vw">
      <Header />
    </el-header>
    <el-container>
      <!-- 侧边 -->
      <el-aside class="workbench-aside" :width="asideWidth">
        <Aside @getAsideWidht="zoom" />
      </el-aside>
      <!-- 主体 -->
      <el-main class="workbench-main">
        <keep-alive include="FileManager">
          <router-view class="workbench-main-view" />
        </keep-alive>
      </el-main>
    </el-container>

    <!-- 选择认证窗口 -->
    <SelectiveAuthenticate />
    <!-- 个人认证窗口 -->
    <PersonalCertificate :recognition="recognition" />
    <!-- 企业认证窗口 -->
    <EnterpriseCertification :recognition="recognition" />
    <!-- 站内信 -->
    <MessageDetail />
    <!-- 操作指引 -->
    <Guide v-if="!guideState && !trialDialogState" @end="guideState = true" />
    <!-- TrialDialog -->
    <TrialDialog v-if="trialDialogState" @close="trialDialogState = false" />
  </el-container>
</template>

<script>
import TrialDialog from 'c/workbench/TrialDialog';
import Guide from 'c/workbench/Guide';
import Header from 'c/workbench/Header';
import Aside from 'c/workbench/Aside';
import SelectiveAuthenticate from 'c/userCommon/SelectiveAuthenticate.vue';
import PersonalCertificate from 'c/userCommon/PersonalCertificate';
import EnterpriseCertification from 'c/userCommon/EnterpriseCertification';
import MessageDetail from './account/MessageDetail.vue';
export default {
  name: 'Workbench',
  data() {
    return {
      asideWidth: '13.54167vw',
      recognition: true,
      guideState: false,
      trialDialogState: true
    };
  },
  created() {
    this.guideState = Boolean(localStorage.guideState);
  },
  components: {
    TrialDialog,
    Guide,
    Header,
    Aside,
    SelectiveAuthenticate,
    PersonalCertificate,
    EnterpriseCertification,
    MessageDetail
  },
  methods: {
    zoom(val) {
      this.asideWidth = val ? '3.5vw' : '13.54167vw';
    }
  }
};
</script>

<style lang="less" scoped>
.workbench {
  background-color: #f6f7fb;
  overflow: hidden;
  &-header {
    position: relative;
    height: 70px;
  }
  &-main {
    &-view {
      box-sizing: border-box;
      height: calc(100% - 40px);
      padding: 20px 28px;
      margin: 20px;
      background-color: #fff;
      box-shadow: 0px 5px 10px 1px rgba(236, 239, 255, 0.5);
      border-radius: 10px;
      // 内层页面头部
      /deep/ .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e3eaff;
        &-left {
          display: flex;
          align-items: baseline;
        }
        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
          padding-right: 35px;
          color: #507cff;
          font-size: 20px;
          font-weight: bold;
          user-select: none;
          &-icon {
            display: inline-block;
            width: 4px;
            height: 14px;
            background: #507cff;
            border-radius: 2px;
            margin-right: 8px;
          }
        }
        &-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
          padding: 0 30px;
          margin-right: 27px;
          line-height: 42px;
          background: #507cff;
          border-radius: 12px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          user-select: none;
          cursor: pointer;
          &:nth-child(2) {
            display: flex;
            padding: 0px 30px 2px 30px;
            height: 38px;
            background-color: #fff;
            border: 1px solid #507cff;
            color: #507cff;
            cursor: pointer;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
          .iconfont,
          .icon {
            margin-left: 10px;
          }
        }
      }

      /deep/ .main {
        &-header {
          display: flex;
          align-items: center;
        }
        // 表单
        .el-form {
          box-sizing: border-box;
          width: 1176px;
          padding: 37px 245px 110px;
          margin: 30px auto 0;
          border: 1px solid #507cff;
          border-radius: 6px;
          .el-row {
            margin-bottom: 30px;
          }
          .form-item-bottom {
            margin-top: 20px;
          }
          .form-item-wrap {
            padding: 18px 12px;
            background: #eaefff;
            border: 1px solid #507cff;
            border-radius: 6px;
          }
          .form-item-label {
            margin-bottom: 20px;
            color: #333;
            font-size: 20px;
          }

          .form-item-inner {
            padding-left: 10px;
            &-label {
              margin-bottom: 20px;
              color: #333;
              font-size: 18px;
            }
          }
          // 每一项
          &-item {
            margin-bottom: 30px;
            &:last-child {
              margin-bottom: 0;
            }
            &.inline {
              display: flex;
              align-items: center;
              .el-form-item__label {
                margin-bottom: 0;
              }
            }
            &__label {
              padding: 0;
              margin-bottom: 16px;
              line-height: initial;
              color: #333;
              font-size: 20px;
            }
            &__content {
              line-height: initial;
              // 输入框
              .el-input {
                // 输入前缀
                &__prefix {
                  top: 50%;
                  left: 11px;
                  transform: translateY(-50%);
                  height: initial;
                  line-height: initial;
                  color: #666;
                }
                &--prefix {
                  .el-input__inner {
                    padding-left: 100px;
                  }
                }
                // 输入框本体
                &__inner {
                  height: 52px;
                  font-size: 18px;
                }
              }
              // 文本输入框
              .el-textarea {
                &__inner {
                  font-size: 18px;
                  &::placeholder {
                    color: #666666;
                  }
                  &:focus {
                    border-color: #507cff;
                  }
                }
                .el-input__count {
                  right: 20px;
                  bottom: 10px;
                  font-size: 14px;
                  color: #666;
                  line-height: initial;
                }
              }
              // 多选框那妞
              .el-checkbox {
                // 勾选框按钮样式
                &-button {
                  margin-right: 42px;
                  &__inner {
                    width: 118px;
                    height: 52px;
                    line-height: 52px;
                    padding: 0;
                    background: #ffffff;
                    border: 1px solid #507cff;
                    border-radius: 6px;
                    color: #333;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  // 选中时样式
                  &.is-checked {
                    .el-checkbox-button__inner {
                      color: #fff;
                      background-color: #507cff;
                    }
                  }
                }
              }
              // 下拉框
              .el-select {
                width: 100%;
              }
            }
            // 表单底部按钮
            &.bottom-btn {
              display: flex;
              justify-content: flex-end;
              margin-top: 50px;
              .el-button {
                width: 188px;
                height: 52px;
                margin-left: 60px;
                line-height: 52px;
                font-size: 18px;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
