<template>
  <Dialog class="trialDialog" :shadow="true">
    <div class="trialDialog-body">
      <img
        class="close-btn"
        src="~a/workbench/close.png"
        @click="$emit('close')"
      />
      <img
        class="trialDialog-title"
        src="~a/workbench/trial-title.png"
        alt=""
      />
      <div class="phone">
        <img
          class="phone-title"
          src="~a/workbench/trial-phone-title.png"
          alt=""
        />
        <div class="phone-item"><i class="icon icon-sj"></i> — 19926633862</div>
        <div class="phone-item">
          <i class="icon icon-dh"></i> — 0755-86336555
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'c/basic/Dialog.vue';
export default {
  name: 'TrialDialog',
  components: {
    Dialog
  }
};
</script>

<style lang="less" scoped>
.trialDialog {
  &-body {
    position: relative;
    box-sizing: border-box;
    width: 345px;
    height: 357px;
    padding-top: 88px;
    background-image: url(~a/workbench/trialDialog-bg.png);
    background-size: 100% 100%;
    .close-btn {
      position: absolute;
      top: 20px;
      right: 14px;
      cursor: pointer;
    }
  }
  &-title {
    display: block;
    width: 282px;
    height: 28px;
    margin: 0 auto;
  }
  .phone {
    position: absolute;
    bottom: 26px;
    left: 35px;
    color: #a7b5c2;
    font-size: 18px;
    &-title {
      width: 165px;
      height: 20px;
      margin-bottom: 20px;
    }
    &-item {
      display: flex;
      align-items: baseline;
      height: 26px;
      padding: 0 10px;
      margin-bottom: 10px;
      background: linear-gradient(
        90deg,
        rgba(112, 148, 255, 0.8),
        rgba(149, 175, 249, 0.8)
      );
      border-radius: 8px;
      color: #fff;
      .icon {
        position: relative;
        top: 2px;
      }
    }
  }
  /deep/ .dialog-header {
    display: none;
  }
  /deep/ .dialog-content {
    padding: 0;
  }
  /deep/ .dialog-shadow {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
